import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormButton } from '../../Objects/Form';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { account_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState({});
	const [notfound, setNotfound] = useState(false);
	const [supportName, setSupportName] = useState('');
	
	useEffect(() => {
	
		const getData = async () => {

			//データ取得
			const newData = (await API.AWS.Get(account_no)).item;
			
			if(Object.keys(newData).length){
				setData(newData);
			}
			else{
				setNotfound(true);
			}

			//サポート情報取得
			const aws_support = (await API.AWS.Get(account_no))?.item?.aws_support?.filter((item) => item?.status === '有効').sort((a, b) => b?.contract_date - a?.contract_date)?.[0] ?? {};
			let support_name = '';
			if(!Object.keys(aws_support).length){
				support_name = 'サポートなし';
			}
			else{
				support_name = aws_support.support_name;
			}
			setSupportName(support_name);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>

				<Form name="form">

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'アカウント番号'} name={'account_no'} defaultValue={data.account_no} readOnly={true} />
					<hr/>
					<FormText label={'請求アカウント'} name={'customer_no'} defaultValue={data.customer_no + '　' + data.customer_name} readOnly={true} />
					<FormText label={'契約日'} name={'contract_date'} defaultValue={dateShapingDate(data.contract_date)} type={'date'} readOnly={true} />
					<FormText label={'アカウント名'} name={'account_name'} defaultValue={data.account_name} readOnly={true} />
					<FormText label={'組織アカウント'} name={'organization_flag'} defaultValue={data.organization_flag} readOnly={true} />
					{
						data.organization_flag === 'メンバーアカウント' &&
							<FormText label={'管理アカウント'} name={'organization_account_no'} defaultValue={data.organization_account_no} readOnly={true} />
					}
					{
						data.organization_flag !== '管理アカウント' &&
							<div>
								<FormText label={'決済サービス'} name={'payment_service'} defaultValue={data.payment_service} readOnly={true} />
								<hr/>
								<FormButton label={'サポート'} value={supportName} onClick={()=>{window.location.href='/aws/' + data.account_no + '/support/'}} />
								<hr/>
								<FormButton label={'割引プラン'} value={'割引プラン一覧'} onClick={()=>{window.location.href='/aws/' + data.account_no + '/discount/'}} />
								<hr/>
								<FormButton label={'課金情報'} value={'課金情報'} onClick={()=>{window.location.href='/aws/' + data.account_no + '/billing/'}} />
							</div>
					}
					<hr/>
					<FormText label={'課金開始月'} name={'bill_start'} value={dateShapingMonth(data.bill_start)} type={'month'} readOnly={true} />
					<FormText label={'課金停止月'} name={'bill_end'} value={dateShapingMonth(data.bill_end)} type={'month'} readOnly={true} />
					<hr/>
					<FormText label={'ステータス'} name={'status'} value={data.status} readOnly={true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>


			</Container>
		);
	}
};


export default withRouter(Default);
