import { useState, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import AwsExports from './@onocomm-system/include/AwsExports';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from './Objects/Header';
import Footer from './Objects/Footer';
import StyleSheet from './Objects/StyleSheet';
import NotFound from './Objects/NotFound';
import LoginSignOut from './Pages/Login/SignOut';
import LoginSignIn from './Pages/Login/SignIn';
import LoginSignInChangePassword from './Pages/Login/SignInChangePassword';
import LoginForgotPassword from './Pages/Login/ForgotPassword';
import LoginForgotPasswordVerify from './Pages/Login/ForgotPasswordVerify';
import AccountMain from './Pages/Account/Main';
import AccountEmail from './Pages/Account/Email';
import AccountEmailVerify from './Pages/Account/EmailVerify';
import AccountPassword from './Pages/Account/Password';
import CustomerList from './Pages/Customer/List';
import CustomerMain from './Pages/Customer/Main';
import DomainList from './Pages/Domain/List';
import DomainMain from './Pages/Domain/Main';
import SSLList from './Pages/SSL/List';
import SSLMain from './Pages/SSL/Main';
import BillDetails from './Pages/Bill/Details';
import BillList from './Pages/Bill/List';
import BillMain from './Pages/Bill/Main';
import NS53DomainList from './Pages/NS53/Domain/List';
import NS53DomainMain from './Pages/NS53/Domain/Main';
import NS53RecordList from './Pages/NS53/Record/List';
import NS53RecordMain from './Pages/NS53/Record/Main';
import NS53List from './Pages/NS53/List';
import NS53Main from './Pages/NS53/Main';
import AWSSupport from './Pages/AWS/Support';
import AWSDiscount from './Pages/AWS/Discount';
import AWSBilling from './Pages/AWS/Billing';
import AWSBillingDetails from './Pages/AWS/BillingDetails';
import AWSList from './Pages/AWS/List';
import AWSMain from './Pages/AWS/Main';
import ServerOP from './Pages/Server/OP';
import ServerList from './Pages/Server/List';
import ServerMain from './Pages/Server/Main';
import MailList from './Pages/Mail/List';
import MailMain from './Pages/Mail/Main';
import MailWhitelist from './Pages/Mail/Whitelist';
//import MailApplication from './Pages/Mail/Application';
import QuotationList from './Pages/Quotation/List';
import Order from './Pages/Order/Order';
import OrderList from './Pages/Order/List';
import OrderMain from './Pages/Order/Main';
import SupportMain from './Pages/Support/Main';
import SupportCaseMain from './Pages/Support/Case/Main';
import SupportCaseList from './Pages/Support/Case/List';
import SupportContact from './Pages/Support/Contact/Main';
import SupportOption from './Pages/Support/Option/Main';
import InvoiceList from './Pages/Invoice/List';
import PrintSwitch from './Pages/Print/Switch';
import PrintInvoice from './Pages/Print/Invoice';
import PrintQuotation from './Pages/Print/Quotation';
import PrintNotFound from './Pages/Print/NotFound';
import Main from './Pages/Main';

Amplify.configure(AwsExports);

const App = () => {

	const [load, setLoad] = useState(true);
	const [auth, setAuth] = useState(undefined);
	const [print, setPrint] = useState(false);

	useEffect(() => {
		
		//���O�C���`�F�b�N
		const checkAuth = async () => {

			//�F�؃X�e�[�^�X�擾
			const getAuthStatus = async () => {

				try{
					//���O�C�����[�U���̎擾
					const username = (await Auth.currentAuthenticatedUser()).username;

					if(username){
						return(true);
					}
					else{
						return(false);
					}
				}
				catch(err) {
					return(false);
				}
			};
			
			//�F�؃X�e�[�^�X�̎擾
			const status = await getAuthStatus();
			
			//���ʋL�^
			if(status){
				setAuth(true);
			}
			else{
				setAuth(false);
			}
		}
		
		//���O�C���`�F�b�N
		checkAuth();
		
		setPrint(sessionStorage.getItem('print') === 'true' ? true : false);
		
		//�ǂݍ��݊���
		setLoad(false);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[true]);
	
	
	//�y�[�W����
	if(load){
		//�ǂݍ��ݒ�
		return(<div />);
	}
	else if(auth === true){
		if(print){
			return (
				<BrowserRouter>
					<Switch>
						<Route path='/print/quotation/:quotation_no' component={PrintQuotation} />
						<Route path='/print/invoice/:invoice_no' component={PrintInvoice} />
						<Route component={PrintNotFound} />
					</Switch>
				</BrowserRouter>
			);
		}
		else{
			return (
				<BrowserRouter>
					<div>
						<Header/>
						<StyleSheet/>
						<Switch>
							<Route exact path='/' component={Main} />
							<Route path='/account/email-verify' component={AccountEmailVerify}/>
							<Route path='/account/email' component={AccountEmail}/>
							<Route path='/account/password' component={AccountPassword}/>
							<Route path='/account' component={AccountMain}/>
							<Route path='/customer/:customer_no' component={CustomerMain} />
							<Route path='/customer' component={CustomerList} />
							<Route path='/domain/new' component={DomainMain} />
							<Route path='/domain/:domain_name' component={DomainMain} />
							<Route path='/domain' component={DomainList} />
							<Route path='/ssl/new' component={SSLMain} />
							<Route path='/ssl/:ssl_name' component={SSLMain} />
							<Route path='/ssl' component={SSLList} />
							<Route path='/bill/:bill_no/details' component={BillDetails} />
							<Route path='/bill/:bill_no' component={BillMain} />
							<Route path='/bill' component={BillList} />
							<Route path='/ns53/:ns53_no/domains/new' component={NS53DomainMain} />
							<Route path='/ns53/:ns53_no/domains' component={NS53DomainList} />
							<Route path='/ns53/:ns53_no/:domain_name/new' component={NS53RecordMain} />
							<Route path='/ns53/:ns53_no/:domain_name/:name/:type' component={NS53RecordMain} />
							<Route path='/ns53/:ns53_no/:domain_name' component={NS53RecordList} />
							<Route path='/ns53/:ns53_no' component={NS53Main} />
							<Route path='/ns53' component={NS53List} />
							<Route path='/aws/:account_no/billing/:date' component={AWSBillingDetails} />
							<Route path='/aws/:account_no/billing' component={AWSBilling} />
							<Route path='/aws/:account_no/discount' component={AWSDiscount} />
							<Route path='/aws/:account_no/support' component={AWSSupport} />
							<Route path='/aws/:account_no' component={AWSMain} />
							<Route path='/aws' component={AWSList} />
							<Route path='/server/:server_no/op' component={ServerOP} />
							<Route path='/server/:server_no' component={ServerMain} />
							<Route path='/server' component={ServerList} />
							{/*<Route path='/mail/application' component={MailApplication} />*/}
							<Route path='/mail/whitelist' component={MailWhitelist} />
							<Route path='/mail/:domain_name' component={MailMain} />
							<Route path='/mail' component={MailList} />
							<Route path='/quotation' component={QuotationList} />
							<Route path='/order/:target/:target_no' component={Order} />
							<Route path='/order/:order_no' component={OrderMain} />
							<Route path='/order' component={OrderList} />
							<Route path='/support/case/:account_no/:case_id' component={SupportCaseMain} />
							<Route path='/support/case/:account_no' component={SupportCaseList} />
							<Route path='/support/case' component={SupportCaseList} />
							<Route path='/support/contact/:account_no_select' component={SupportContact} />
							<Route path='/support/contact' component={SupportContact} />
							<Route path='/support/option' component={SupportOption} />
							<Route path='/support' component={SupportMain} />
							<Route path='/invoice' component={InvoiceList} />
							<Route path='/print' component={PrintSwitch} />
							<Route path='/sign-out' component={LoginSignOut}/>
							<Route component={NotFound} />
						</Switch>
						<Footer/>
					</div>
				</BrowserRouter>
			);
		}
	}
	else{
		return (
			<BrowserRouter>
				<div>
					<StyleSheet/>
					<Switch>
						<Route path='/forgot-password-verify/:email' component={LoginForgotPasswordVerify} />
						<Route path='/forgot-password-verify' component={LoginForgotPasswordVerify} />
						<Route path='/forgot-password' component={LoginForgotPassword} />
						<Route path='/signin-change-password/:email' component={LoginSignInChangePassword} />
						<Route component={LoginSignIn} />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
};

export default App;

