import { useState, useEffect  } from 'react';
import { Storage } from 'aws-amplify';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Card,
	Button,
	Badge,
} from 'react-bootstrap';
import { ModalAlert } from '../../../Objects/Modal';
import { useParams, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const Default = () => {

	const { account_no, case_id } = useParams();
	const [alert, setAlert] = useState([]);
	const [load, setLoad] = useState(true);
	const [data, setData] = useState({});
	const [comment, setComment] = useState([]);
	const [user, setUser] = useState({});
	const [customerNo, setCustomerNo] = useState('');
	const [postFlag, setPostFlag] = useState(false);
	const [reload, setReload] = useState(null);
	const [aiLoad, setAiLoad] = useState(false);
	const [visibleComments, setVisibleComments] = useState(5);

	useEffect(() => {
	
		const getData = async () => {
			
			const resultUser = await API.User.Get();
			const itemUser = resultUser.item;

			const resultAWSSupport = await API.AWSSupport.Get(case_id);
			const itemAWSSupport   = resultAWSSupport.item;
			

			if(Object.keys(itemAWSSupport).length === 0){
				setAlert(['ケースが見つかりません']);
				setLoad(false);
				return;
			}
			
			const resultAWSSupportComment = await API.AWSSupport.CommentGetAll(case_id);
			const itemAWSSupportComment  = resultAWSSupportComment.items;

			//ケースについているコメントを追加
			itemAWSSupportComment.push(
				{
					"case_id": case_id,
					"date": itemAWSSupport.date_add,
					"user_name": itemAWSSupport.user_name,
					"account_no": account_no,
					"customer_no": itemAWSSupport.customer_no,
					"text_value": itemAWSSupport.text_value,
					"file1_original_name": itemAWSSupport.file1_original_name,
					"file2_original_name": itemAWSSupport.file2_original_name,
					"file3_original_name": itemAWSSupport.file3_original_name,
					"file1_name": itemAWSSupport.file1_name,
					"file2_name": itemAWSSupport.file2_name,
					"file3_name": itemAWSSupport.file3_name,
				}
			);
			itemAWSSupportComment.sort((a, b) => new Date(b.date) - new Date(a.date));

			const resultAWS = await API.AWS.Get(account_no);
			const itemAWS = resultAWS.item;
			const support_name = itemAWS?.aws_support?.filter((item) => item?.status === '有効').sort((a, b) => b?.contract_date - a?.contract_date)?.[0]?.support_name ?? '';
			const status = itemAWSSupport?.status ?? '';
			const customer_no = itemAWSSupport?.customer_no ?? '';

			let postFlag = false;
			if(status !== '無効'){
				postFlag = true;
			}
			if(itemAWSSupport.type === 'technical' && !support_name){
				postFlag = false;
			}
			else{
				postFlag = true;
			}

			if(itemAWSSupport.status === 'オープン' && !itemAWSSupportComment?.[0]?.ai_flag){
				setAiLoad(true);
			}
			
			//データセット
			setData(itemAWSSupport);
			setComment(itemAWSSupportComment);
			setUser(itemUser);
			setCustomerNo(customer_no);
			setPostFlag(postFlag);

			//表示開始
			setLoad(false);

			console.log(reload);
			
		};
		
		getData();
		
	},[account_no, case_id, reload]);

	useEffect(() => {
	
		const getData = async () => {
			
			const resultAWSSupportComment = await API.AWSSupport.CommentGetAll(case_id);
			const itemAWSSupportComment  = resultAWSSupportComment.items;
			itemAWSSupportComment.sort((a, b) => new Date(b.date) - new Date(a.date));
			const ai_flag = itemAWSSupportComment?.[0]?.ai_flag ? true : false;
			if(ai_flag){
				setAiLoad(false);
				setReload(reload+1);
			}
		};
		
		if(aiLoad){

			const intervalId = setInterval(getData, 10000); // 10秒ごとにgetDataを実行

			// クリーンアップ関数
			return () => clearInterval(intervalId);
		}
		
	},[aiLoad, case_id, reload]);

	const formatDate = (date) => {
		const dateObj      = new Date(Number(date) * 1000);
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second       = ('00' + dateObj.getSeconds().toString()).slice(-2);
		const dateText     = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
		return (dateText);
	};
	
	const handleSubmit = async (event) => {

		event.preventDefault();
		event.stopPropagation();

		setLoad(true);

		try{

			Storage.configure({ AWSS3: { bucket: 'onocomm-system-support-upload', region: 'ap-northeast-1', level: 'public' } });

			const customer_no = customerNo;
			const user_name = user.user_name;
			const text_value = event.target.text_value.value;
			const file1Object = event.target?.file1?.files?.[0] ?? null;
			const file2Object = event.target?.file2?.files?.[0] ?? null;
			const file3Object = event.target?.file3?.files?.[0] ?? null;
			const type = data.type;

			if(!postFlag){
				throw new Error('技術的なお問い合わせは契約しているサポートがないため、お問い合わせ出来ません');
			}

			if(data.status === 'クローズ'){
				throw new Error('ケースがクローズされているため、お問い合わせ出来ません');
			}

			if(!text_value){
				throw new Error('お問い合わせ内容が入力されていません');
			}

			if(!customer_no){
				throw new Error('お客様番号が取得できませんでした');
			}

			if(!user_name){
				throw new Error('ユーザー名が取得できませんでした');
			}

			let file1_original_name = null;
			let file1_name = null;
			if(file1Object && type === 'technical'){
				const file1Extension = file1Object.name.split('.').pop();
				const file1Size = file1Object.size;
				if(file1Size > 1024 * 1024 * 3){
					throw new Error('ファイルサイズが3MBを超えています');
				}
				file1_original_name = file1Object.name;
				file1_name = uuidv4() + '.' + file1Extension;
				await Storage.put(file1_name, file1Object);
			}
			let file2_original_name = null;
			let file2_name = null;
			if(file2Object && type === 'technical'){
				const file2Extension = file2Object.name.split('.').pop();
				const file2Size = file2Object.size;
				if(file2Size > 1024 * 1024 * 3){
					throw new Error('ファイルサイズが3MBを超えています');
				}
				file2_original_name = file2Object.name;
				file2_name = uuidv4() + '.' + file2Extension;
				await Storage.put(file2_name, file2Object);
			}
			let file3_original_name = null;
			let file3_name = null;
			if(file3Object && type === 'technical'){
				const file3Extension = file3Object.name.split('.').pop();
				const file3Size = file3Object.size;
				if(file3Size > 1024 * 1024 * 3){
					throw new Error('ファイルサイズが3MBを超えています');
				}
				file3_original_name = file3Object.name;
				file3_name = uuidv4() + '.' + file3Extension;
				await Storage.put(file3_name, file3Object);
			}

			const params = {
				case_id,
				user_name,
				account_no,
				customer_no,
				text_value,
				file1_original_name,
				file1_name,
				file2_original_name,
				file2_name,
				file3_original_name,
				file3_name,
			};

			const result = await API.AWSSupport.CommentCreate(params);

			if(result.error){
				throw new Error(result.error);
			}

			const resultDate = result?.ref?.data?.createAWSSupportComment?.date;

			if(!resultDate){
				throw new Error('コメントの更新が取得できませんでした');
			}

			setReload(resultDate);

		}
		catch(err){
			setAlert([err.message]);
		}

		//ロック解除
		setLoad(false);
	};

	const handleClose = () => {
		(async () => {
			try{
				setLoad(true);
				const result = await API.AWSSupport.Update(case_id);
				if(result.error){
					throw new Error(result.error);
				}
				setTimeout(() => {
					setReload(Math.round((new Date()).getTime() / 1000));
					setLoad(false);
				}, 10000); // 10000ミリ秒（10秒）
			}
			catch(err){
				setAlert([err.message]);
			}
		})();
	};

	const handleFileDownload = async (file_original_name, file_name) => {

		console.log(file_original_name);
		console.log(file_name);

		try {

			Storage.configure({ AWSS3: { bucket: 'onocomm-system-support-upload', region: 'ap-northeast-1', level: 'public' } });

			// S3からファイルをダウンロード
			const result = await Storage.get(file_name, { download: true });
			const blob = result.Body;

			// ブラウザでダウンロード
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = file_original_name;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

		} catch (error) {
			console.error('Error downloading file', error);
		}
	};

	const loadMoreComments = () => {
		setVisibleComments(prevVisibleComments => prevVisibleComments + 5);
	};

	const escapeHTML = (text) => {
		const div = document.createElement('div');
		div.appendChild(document.createTextNode(text));
		return div.innerHTML;
	};

	const urlify = (text) => {
		const urlPattern = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlPattern, (url) => {
			return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
		});
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={()=>setAlert([])} />
				<Row>
					<Col sm={10}>
						<h4>ケースID：{case_id}</h4>
					</Col>
					<Col sm={2} className="text-right">
						<Button variant="secondary" onClick={handleClose} disabled={data.status !== 'クローズ' ? false : true}>クローズする</Button>
					</Col>
				</Row>
				<Row style={{ marginTop: 20}}>
					<Col sm={12}>
						<Card>
							<Card.Header>ケースの詳細</Card.Header>
							<Card.Body>
								<Card.Text>
									<Row>
										<Col sm={6} className="border-right">
											題名<br/>
											{data.subject}<br/>
											<br/>
											日付<br/>
											{formatDate(data.date_add)}<br/>
											<br/>
											アカウント<br/>
											{data.account_no}<br/>
											{(()=>{
												if(data.build_id){
													return (
														<>
															<br/>
															ビルドID<br/>
															{data.build_id}<br/>
															<br/>
															費用<br/>
															{
															data.build_off ?
															(
																data.build_price - Math.floor(data.build_price * (data.build_off / 100)))?.toLocaleString() :
																data.build_price?.toLocaleString()
															}円（税別） {data.off ? <><Badge variant="danger">{data.build_off}%OFF</Badge><br /></> : ''}
														<br/>
														</>
													)
												}
											})()}
										</Col>
										<Col sm={6}>
											ステータス<br/>
											{data.status}<br/>
											<br/>
											種別<br/>
											{data.type}<br/>
											<br/>
											{data.onocomm_flag ? '当社担当者' :'お問合せされた方'}<br/>
											{data.user_name}<br/>
											{(()=>{
												if(data.build_id){
													return (
														<>
															<br/>
															詳細<br/>
															{data.build_description}<br/>
															<br/>
															期間<br/>
															{data.build_period}日間<br/>
														</>
													)
												}
											})()}
										</Col>
									</Row>
								</Card.Text>
							</Card.Body>
						</Card>

					</Col>
				</Row>
				　
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>やりとり</Card.Header>
							{aiLoad ? 
							<Card.Body>
								<Card.Text>
									<Row>
										<Col sm={3} className="border-right">
											日付<br/>
											ー<br/>
											<br/>
											当社担当者<br/>
											自動応答AI<br/>
											<br/>
										</Col>
										<Col sm={9} className="d-flex flex-column align-items-center justify-content-center">
											<Image src="/img/loading.gif" />
											<div className="text-center"style={{ marginTop: 20 }}>
												AIがお問い合わせ内容を解析中です。しばらくお待ちください。
											</div>
										</Col>
									</Row>
								</Card.Text>
								<hr/>
							</Card.Body>
							: <></>}
							{comment.slice(0, visibleComments).map((item, index) => (
								<Card.Body key={index}>
									<Card.Text>
										<Row>
											<Col sm={3} className="border-right">
												日付<br/>
												{formatDate(item.date)}<br/>
												<br/>
												{item.onocomm_flag ? '当社担当者' :'お問合せされた方'}<br/>
												{item.user_name}<br/>
												<br/>
											</Col>
											<Col sm={9}>
												<pre
													style={{
														whiteSpace: 'pre-wrap',
														wordWrap: 'break-word'
													}} 
													dangerouslySetInnerHTML={{ __html: urlify(escapeHTML(item.text_value)) }}
												/><br/>
												<br/>
												{item.file1_original_name && (
												<>添付ファイル1：
													<button
													onClick={() => handleFileDownload(item.file1_original_name, item.file1_name)}
													style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}
													>
													{item.file1_original_name}
													</button><br/>
												</>
												)}
												{item.file2_original_name && (
												<>添付ファイル2：
													<button
													onClick={() => handleFileDownload(item.file2_original_name, item.file2_name)}
													style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}
													>
													{item.file2_original_name}
													</button><br/>
												</>
												)}
												{item.file3_original_name && (
												<>添付ファイル3：
													<button
													onClick={() => handleFileDownload(item.file3_original_name, item.file3_name)}
													style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}
													>
													{item.file3_original_name}
													</button><br/>
												</>
												)}
											</Col>
										</Row>
									</Card.Text>
									<hr/>
								</Card.Body>
							))}
							{visibleComments < comment.length && (
								<div className="text-center">
								<Button onClick={loadMoreComments} variant="link">
									さらに読み込む
								</Button>
								</div>
							)}
						</Card>
					</Col>
				</Row>
				　
				{(()=>{
					if(data.status !== 'クローズ'){
						return (
							<Row>
								<Col sm={12}>
									<Card>
										<Card.Header>コメント追加</Card.Header>
										<Card.Body>
											<Form onSubmit={handleSubmit}>
												<Form.Group controlId="formTechnicalEmail">
													<Form.Label>
														お問い合わせ内容（パスワードや秘密情報、個人情報の入力は行わないようにしてください）<br/>
														<small>
															お問合せ及び依頼をいただいた内容は、AWSが提供する生成AIサービスのBedrockに提供されます。<br/>
															Bedrockに提供されたデータは、学習データに用いられることはありません。
														</small>
													</Form.Label>
													<Form.Control as="textarea" name="text_value" rows={10} placeholder="お問い合わせ内容" disabled={!postFlag} required />
												</Form.Group>
												<Form.Group controlId="formTechnicalFile1" style={{ display: data.type === 'technical' ? 'block' : 'none' }}>
													<Form.File name="file1" id="custom-file" label="ファイル1を添付" disabled={!postFlag || data.type === 'basic'} />
												</Form.Group>
												<Form.Group controlId="formTechnicalFile2" style={{ display: data.type === 'technical' ? 'block' : 'none' }}>
													<Form.File name="file2" id="custom-file" label="ファイル2を添付" disabled={!postFlag || data.type === 'basic'} />
												</Form.Group>
												<Form.Group controlId="formTechnicalFile3" style={{ display: data.type === 'technical' ? 'block' : 'none' }}>
													<Form.File name="file3" id="custom-file" label="ファイル3を添付" disabled={!postFlag || data.type === 'basic'} />
												</Form.Group>
												<Button variant="primary" type="submit" disabled={!postFlag}>コメントを追加する</Button>
											</Form>

										</Card.Body>
									</Card>
								</Col>
							</Row>
						);
					}
				})()}
			</Container>
		);
	}
};

export default withRouter(Default);
