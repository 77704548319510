
const GraphQL = {
	getUserCurrent :	`query getUserCurrent {
				getUserCurrent {
					user_id
					customer_no
					customer_name
					customer_group
					user_name
					email
					date_add
					date_edit
					status
				}
			}`,
	scanCustomer : 	`query scanCustomer($limit: Int, $nextToken: String) {
					scanCustomer(limit: $limit, nextToken: $nextToken) {
						items {
							customer_no
							customer_id
							customer_name
							customer_name_furi
							customer_name_en
							contract_kbn
							contractor_name
							contractor_name_furi
							contractor_tel
							contractor_birth
							contractor_zip
							contractor_addr_1
							contractor_addr_2
							contractor_addr_3
							contractor_addr_en_1
							contractor_addr_en_2
							contractor_addr_en_3
							person_section
							person_name
							person_name_furi
							person_tel
							person_fax
							person_mail
							tech_section
							tech_name
							tech_name_furi
							tech_tel
							tech_fax
							tech_mail
							tech_emer_tel
							invoice_pay_kbn
							invoice_bank_pay_kbn
							invoice_section
							invoice_name
							invoice_name_furi
							invoice_zip
							invoice_addr_1
							invoice_addr_2
							invoice_addr_3
							invoice_person_section
							invoice_person_name
							invoice_person_name_furi
							invoice_person_tel
							invoice_person_fax
							invoice_person_mail
							invoice_mail
							invoice_mail_cc_1
							invoice_mail_cc_2
							invoice_mail_cc_3
							invoice_mail_cc_4
							invoice_mail_cc_5
							invoice_text
							invoice_mail_flag
							invoice_seal_flag
							invoice_aws_flag
							date_add
							date_edit
						}
						nextToken
					}
				}`,
	getCustomer :	`query getCustomer($customer_no: String!) {
				getCustomer(customer_no: $customer_no) {
					customer_no
					customer_id
					customer_name
					customer_name_furi
					customer_name_en
					contract_kbn
					contractor_name
					contractor_name_furi
					contractor_tel
					contractor_birth
					contractor_zip
					contractor_addr_1
					contractor_addr_2
					contractor_addr_3
					contractor_addr_en_1
					contractor_addr_en_2
					contractor_addr_en_3
					person_section
					person_name
					person_name_furi
					person_tel
					person_fax
					person_mail
					tech_section
					tech_name
					tech_name_furi
					tech_tel
					tech_fax
					tech_mail
					tech_emer_tel
					invoice_pay_kbn
					invoice_bank_pay_kbn
					invoice_section
					invoice_name
					invoice_name_furi
					invoice_zip
					invoice_addr_1
					invoice_addr_2
					invoice_addr_3
					invoice_person_section
					invoice_person_name
					invoice_person_name_furi
					invoice_person_tel
					invoice_person_fax
					invoice_person_mail
					invoice_mail
					invoice_mail_cc_1
					invoice_mail_cc_2
					invoice_mail_cc_3
					invoice_mail_cc_4
					invoice_mail_cc_5
					invoice_text
					invoice_mail_flag
					invoice_seal_flag
					invoice_aws_flag
					date_add
					date_edit
				}
			}`,
	updateCustomer :	`mutation updateCustomer($data: CustomerInput!) {
				updateCustomer(input: $data) {
					customer_no
				}
			}`,
	scanDomain : 	`query scanDomain($limit: Int, $nextToken: String) {
					scanDomain(limit: $limit, nextToken: $nextToken) {
						items {
							domain_name
							customer_no
							customer_name
							domain_id
							domain_kbn
							tld_kbn
							regist_kbn
							price_notax
							init_price_notax
							contract_years
							expiration
							billing_date
							auth_code
							bill_start
							bill_end
							registrant_id
							registrant_name
							registrant_name_en
							registrant_representative_name
							registrant_representative_division
							registrant_email_address
							registrant_phone_number
							registrant_fax_number
							registrant_postal_code
							registrant_prefecture
							registrant_postal_address1
							registrant_postal_address2
							registrant_postal_address3
							registrant_postal_address_en1
							registrant_postal_address_en2
							registrant_postal_address_en3
							registrant_country_code
							registrant_ced_entity_type
							registrant_ced_otherentity_type
							registrant_ced_ident_form
							registrant_ced_other_ident_form
							registrant_ced_ident_number
							registrant_ced_country_code
							contact_id
							contact_name
							contact_name_en
							contact_webpage
							contact_email_address
							contact_phone_number
							contact_fax_number
							contact_postal_code
							contact_prefecture
							contact_postal_address1
							contact_postal_address2
							contact_postal_address3
							contact_postal_address_en1
							contact_postal_address_en2
							contact_postal_address_en3
							contact_country_code
							nameserver
							status
							date_add
							date_edit
						}
						nextToken
					}
				}`,
	getDomain :	`query getDomain($domain_name: String!) {
				getDomain(domain_name: $domain_name) {
					domain_name
					customer_no
					customer_name
					domain_id
					domain_kbn
					tld_kbn
					regist_kbn
					price_notax
					init_price_notax
					contract_years
					expiration
					billing_date
					auth_code
					bill_start
					bill_end
					registrant_id
					registrant_name
					registrant_name_en
					registrant_representative_name
					registrant_representative_division
					registrant_email_address
					registrant_phone_number
					registrant_fax_number
					registrant_postal_code
					registrant_prefecture
					registrant_postal_address1
					registrant_postal_address2
					registrant_postal_address3
					registrant_postal_address_en1
					registrant_postal_address_en2
					registrant_postal_address_en3
					registrant_country_code
					registrant_ced_entity_type
					registrant_ced_otherentity_type
					registrant_ced_ident_form
					registrant_ced_other_ident_form
					registrant_ced_ident_number
					registrant_ced_country_code
					contact_id
					contact_name
					contact_name_en
					contact_webpage
					contact_email_address
					contact_phone_number
					contact_fax_number
					contact_postal_code
					contact_prefecture
					contact_postal_address1
					contact_postal_address2
					contact_postal_address3
					contact_postal_address_en1
					contact_postal_address_en2
					contact_postal_address_en3
					contact_country_code
					nameserver
					status
					date_add
					date_edit
				}
			}`,
	updateDomain :	`mutation updateDomain($data: DomainInput!) {
				updateDomain(input: $data) {
					domain_name
				}
			}`,
	scanSSL : 	`query scanSSL($limit: Int, $nextToken: String) {
					scanSSL(limit: $limit, nextToken: $nextToken) {
						items {
							ssl_name
							customer_id
							customer_no
							customer_name
							ssl_id
							ssl_kbn
							price_notax
							contract_years
							status
							expiration
							billing_date
							setup_date
							double_domain_type
							approval_type
							approval_mail
							approval_file
							approval_text
							target_type
							target_server
							ov_state
							ov_locality
							ov_org_name
							ov_org_unit
							ov_org_kbn
							ov_org_name_jpn
							ov_org_unit_jpn
							ov_person_name_1_jpn
							ov_person_name_2_jpn
							ov_person_name_1_eng
							ov_person_name_2_eng
							ov_postcode
							ov_addr_1_jpn
							ov_addr_2_jpn
							ov_addr_3_jpn
							ov_addr_4_jpn
							ov_addr_1_eng
							ov_addr_2_eng
							ov_addr_3_eng
							ov_addr_4_eng
							ov_tel
							ov_fax
							bill_start
							bill_end
							date_add
							date_edit
							is_get_key
							process
						}
						nextToken
					}
				}`,
	getSSL :	`query getSSL($ssl_name: String!) {
				getSSL(ssl_name: $ssl_name) {
					ssl_name
					customer_id
					customer_no
					customer_name
					ssl_id
					ssl_kbn
					price_notax
					contract_years
					status
					expiration
					billing_date
					setup_date
					double_domain_type
					approval_type
					approval_mail
					approval_file
					approval_text
					target_type
					target_server
					ov_state
					ov_locality
					ov_org_name
					ov_org_unit
					ov_org_kbn
					ov_org_name_jpn
					ov_org_unit_jpn
					ov_person_name_1_jpn
					ov_person_name_2_jpn
					ov_person_name_1_eng
					ov_person_name_2_eng
					ov_postcode
					ov_addr_1_jpn
					ov_addr_2_jpn
					ov_addr_3_jpn
					ov_addr_4_jpn
					ov_addr_1_eng
					ov_addr_2_eng
					ov_addr_3_eng
					ov_addr_4_eng
					ov_tel
					ov_fax
					bill_start
					bill_end
					date_add
					date_edit
					is_get_key
					process
				}
			}`,
	updateSSL :	`mutation updateSSL($data: SSLInput!) {
				updateSSL(input: $data) {
					ssl_name
				}
			}`,
	scanSSLApplication : 	`query scanSSLApplication($limit: Int, $nextToken: String) {
					scanSSLApplication(limit: $limit, nextToken: $nextToken) {
						items {
							request_id
							ssl_name
							customer_no
							customer_name
							ssl_kbn
							price_notax
							contract_years
							status
							lock_flag
							double_domain_type
							approval_type
							approval_file
							approval_text
							approval_result
							cer_key
							crt_key
							csr_key
							key_key
							ori_key
							biko_text
							process
							date_add
							date_edit
						}
						nextToken
					}
				}`,
	getSSLApplication :	`query getSSLApplication($request_id: String!) {
				getSSLApplication(request_id: $request_id) {
					request_id
					ssl_name
					customer_no
					customer_name
					ssl_kbn
					price_notax
					contract_years
					status
					lock_flag
					double_domain_type
					approval_type
					approval_file
					approval_text
					approval_result
					cer_key
					crt_key
					csr_key
					key_key
					ori_key
					biko_text
					process
					date_add
					date_edit
				}
			}`,
	createSSLApplication :	`mutation createSSLApplication($data: SSLApplicationInput!) {
				createSSLApplication(input: $data) {
					ssl_name
				}
			}`,
	updateSSLApplication :	`mutation updateSSLApplication($data: SSLApplicationInput!) {
				updateSSLApplication(input: $data) {
					ssl_name
				}
			}`,
	scanQuotation : 	`query scanQuotation($limit: Int, $nextToken: String) {
					scanQuotation(limit: $limit, nextToken: $nextToken) {
						items {
							quotation_no
							title
							customer_no
							customer_name
							person
							i_total_price
							i_total_price_notax
							i_total_tax
							m_total_price
							m_total_price_notax
							m_total_tax
							serial
							biko_text
							flag_1
							flag_2
							flag_3
							cutoff_flag
							date_quotation
							date_limit
							date_add
							date_edit
							details {
								details
								i_price_notax
								i_sub_price_notax
								i_tax
								m_price_notax
								m_sub_price_notax
								m_tax
								piece
								piece_type
								tax_per
								sort
								uuid
							}
						}
						nextToken
					}
				}`,
	getQuotation :	`query getQuotation($quotation_no: String!) {
				getQuotation(quotation_no: $quotation_no) {
					quotation_no
					title
					customer_no
					customer_name
					person
					i_total_price
					i_total_price_notax
					i_total_tax
					m_total_price
					m_total_price_notax
					m_total_tax
					serial
					biko_text
					flag_1
					flag_2
					flag_3
					cutoff_flag
					date_quotation
					date_limit
					date_add
					date_edit
					details {
						details
						i_price_notax
						i_sub_price_notax
						i_tax
						m_price_notax
						m_sub_price_notax
						m_tax
						piece
						piece_type
						tax_per
						sort
						uuid
					}
				}
			}`,
	scanOrder : 	`query scanOrder($limit: Int, $nextToken: String) {
					scanOrder(limit: $limit, nextToken: $nextToken) {
						items {
							order_no
							customer_no
							customer_name
							title
							i_total_price
							i_total_price_notax
							i_total_tax
							m_total_price
							m_total_price_notax
							m_total_tax
							biko_text
							target
							target_no
							date_order
							date_add
							date_edit
						}
						nextToken
					}
				}`,
	getOrder :	`query getOrder($order_no: String!) {
				getOrder(order_no: $order_no) {
					order_no
					customer_no
					customer_name
					title
					i_total_price
					i_total_price_notax
					i_total_tax
					m_total_price
					m_total_price_notax
					m_total_tax
					biko_text
					target
					target_no
					date_order
					date_add
					date_edit
				}
			}`,
	createOrder :	`mutation createOrder($data: OrderInput!) {
				createOrder(input: $data) {
					order_no
				}
			}`,
	queryInvoice : 	`query queryInvoice($invoice_flag: String!, $start_date: Int!, $end_date: Int!, $limit: Int, $nextToken: String) {
					queryInvoice(invoice_flag: $invoice_flag, start_date: $start_date, end_date: $end_date, limit: $limit, nextToken: $nextToken) {
						items {
							invoice_no
							date_invoice
							date_payment
							customer_no
							customer_name
							pay_kbn
							bank_pay_kbn
							bank_pay_text
							aws_flag
							seal_flag
							mail_flag
							mail
							mail_cc_1
							mail_cc_2
							mail_cc_3
							mail_cc_4
							mail_cc_5
							zip
							addr_1
							addr_2
							addr_3
							name
							name_furi
							section
							total_price
							total_price_notax
							total_tax
							invoice_flag
							flag_1
							flag_2
							flag_3
							cutoff_flag
							date_add
							date_edit
							details {
								details
								price_notax
								piece
								piece_type
								sub_price_notax
								tax
								tax_per
								sort
								uuid
							}
						}
						nextToken
					}
				}`,
	getInvoice :	`query getInvoice($invoice_no: String!) {
				getInvoice(invoice_no: $invoice_no) {
						invoice_no
						date_invoice
						date_payment
						customer_no
						customer_name
						pay_kbn
						bank_pay_kbn
						bank_pay_text
						aws_flag
						seal_flag
						mail_flag
						mail
						mail_cc_1
						mail_cc_2
						mail_cc_3
						mail_cc_4
						mail_cc_5
						zip
						addr_1
						addr_2
						addr_3
						name
						name_furi
						section
						total_price
						total_price_notax
						total_tax
						invoice_flag
						flag_1
						flag_2
						flag_3
						cutoff_flag
						date_add
						date_edit
						details {
							details
							price_notax
							piece
							piece_type
							sub_price_notax
							tax
							tax_per
							sort
							uuid
						}
						eligible_invoice
						invoice_version
						tax_subs
				}
			}`,
	scanBill : 	`query scanBill($limit: Int, $nextToken: String) {
					scanBill(limit: $limit, nextToken: $nextToken) {
						items {
							bill_no
							customer_no
							customer_name
							title
							total_price_notax
							contract_date
							bill_start
							bill_end
							bill_month01
							bill_month02
							bill_month03
							bill_month04
							bill_month05
							bill_month06
							bill_month07
							bill_month08
							bill_month09
							bill_month10
							bill_month11
							bill_month12
							status
							date_add
							date_edit
							details{
								uuid
								details
								price_notax
								piece
								piece_type
								sub_price_notax
								sort
							}
						}
						nextToken
					}
				}`,
	getBill :	`query getBill($bill_no: String!) {
				getBill(bill_no: $bill_no) {
					bill_no
					customer_no
					customer_name
					title
					total_price_notax
					contract_date
					bill_start
					bill_end
					bill_month01
					bill_month02
					bill_month03
					bill_month04
					bill_month05
					bill_month06
					bill_month07
					bill_month08
					bill_month09
					bill_month10
					bill_month11
					bill_month12
					status
					date_add
					date_edit
					details{
						uuid
						details
						price_notax
						piece
						piece_type
						sub_price_notax
						sort
					}
				}
			}`,
	scanNS53 : 	`query scanNS53($limit: Int, $nextToken: String) {
					scanNS53(limit: $limit, nextToken: $nextToken) {
						items {
							ns53_no
							customer_no
							customer_name
							title
							price_notax
							contract_date
							domain_num
							record_num
							bill_start
							bill_end
							status
							date_add
							date_edit
						}
						nextToken
					}
				}`,
	getNS53 :	`query getNS53($ns53_no: String!) {
				getNS53(ns53_no: $ns53_no) {
					ns53_no
					customer_no
					customer_name
					title
					price_notax
					contract_date
					domain_num
					record_num
					bill_start
					bill_end
					status
					date_add
					date_edit
				}
			}`,
	queryNS53Domain : 	`query queryNS53Domain($ns53_no: String!, $limit: Int, $nextToken: String) {
					queryNS53Domain(ns53_no: $ns53_no, limit: $limit, nextToken: $nextToken) {
						items {
							ns53_no
							name
							date_add
							date_edit
						}
						nextToken
					}
				}`,
	createNS53Domain :	`mutation createNS53Domain($data: NS53DomainInput!) {
				createNS53Domain(input: $data) {
					ns53_no
				}
			}`,
	deleteNS53Domain :	`mutation deleteNS53Domain($name: String!) {
				deleteNS53Domain(name: $name) {
					ns53_no
				}
			}`,
	queryNS53Record : 	`query queryNS53Record($domain_name: String!, $limit: Int, $nextToken: String) {
					queryNS53Record(domain_name: $domain_name, limit: $limit, nextToken: $nextToken) {
						items {
							domain_name
							name
							type
							content
							ttl
							date_add
							date_edit
						}
						nextToken
					}
				}`,
	getNS53Record : 	`query getNS53Record($name: String!, $type: String!) {
					getNS53Record(name: $name, type: $type) {
						domain_name
						name
						type
						content
						ttl
						date_add
						date_edit
					}
				}`,
	createNS53Record :	`mutation createNS53Record($data: NS53RecordInput!) {
				createNS53Record(input: $data) {
					ns53_no
				}
			}`,
	updateNS53Record :	`mutation updateNS53Record($data: NS53RecordInput!) {
				updateNS53Record(input: $data) {
					ns53_no
				}
			}`,
	deleteNS53Record :	`mutation deleteNS53Record($name: String!, $type: String!) {
				deleteNS53Record(name: $name, type: $type) {
					ns53_no
				}
			}`,
	scanAWS : 	`query scanAWS($limit: Int, $nextToken: String) {
					scanAWS(limit: $limit, nextToken: $nextToken) {
						items {
							account_no
							account_name
							customer_no
							customer_name
							contract_date
							payment_service
							organization_flag
							organization_account_no
							bill_link
							bill_start
							bill_end
							status
							date_add
							date_edit
							aws_support{
								uuid
								support_name
								contract_date
								price_notax
								bill_start
								bill_end
								status
							}
							aws_discount{
								uuid
								percent
								contract_date
								bill_start
								bill_end
								status
							}
							support_case_flag
						}
						nextToken
					}
				}`,
	getAWS :	`query getAWS($account_no: String!) {
				getAWS(account_no: $account_no) {
					account_no
					account_name
					customer_no
					customer_name
					contract_date
					payment_service
					organization_flag
					organization_account_no
					bill_link
					bill_start
					bill_end
					status
					date_add
					date_edit
					aws_support{
						uuid
						support_name
						contract_date
						price_notax
						bill_start
						bill_end
						status
					}
					aws_discount{
						uuid
						percent
						contract_date
						bill_start
						bill_end
						status
					}
		}
			}`,
		queryAWSBilling : 	`query queryAWSBilling($account_no: String!, $limit: Int, $nextToken: String) {
			queryAWSBilling(account_no: $account_no, limit: $limit, nextToken: $nextToken) {
				items {
					billing_id
					account_no
					account_name
					payer_account_no
					date
					cost
					date_add
					invoice_id
				}
				nextToken
			}
		}`,
		queryAWSBillingDetails : 	`query queryAWSBillingDetails($account_no: String!, $date: String!, $limit: Int, $nextToken: String) {
			queryAWSBillingDetails(account_no: $account_no, date: $date, limit: $limit, nextToken: $nextToken) {
				items {
					description
					account_no
					account_name
					payer_account_no
					date
					product
					region
					unit
					amount
					rate
					cost
					date_add
					invoice_id
				}
				nextToken
			}
		}`,
		scanServer : 	`query scanServer($limit: Int, $nextToken: String) {
					scanServer(limit: $limit, nextToken: $nextToken) {
						items {
							server_no
							customer_no
							customer_name
							account_no
							server_name
							server_type
							instance_id
							instance_type
							instance_price_notax
							instance_min_num
							instance_max_num
							web_scale_flag
							db_scale_flag
							disk_num
							disk_unit
							disk_price_notax
							bakcup_num
							bakcup_unit
							bakcup_price_notax
							transfer_num
							transfer_unit
							transfer_price_notax
							payment_type
							support_type
							support_price_notax
							op_price_notax
							total_price_notax
							admin_url
							admin_user
							admin_passwd
							web_url
							basic_user
							basic_passwd
							scp_user
							ftp_host
							ftp_user
							ftp_passwd
							phpmyadmin_url
							phpmyadmin_user
							phpmyadmin_passwd
							db_num
							db_host
							db_port
							db_user
							db_passwd
							db_engine
							ip
							fqdn
							os
							security_group
							bill_start
							bill_end
							contract_date
							date_add
							date_edit
							status
							op{
								uuid
								details
								price_notax
								piece
								piece_type
								sub_price_notax
								sort
							}
						}
						nextToken
					}
				}`,
	getServer :	`query getServer($server_no: String!) {
				getServer(server_no: $server_no) {
					server_no
					customer_no
					customer_name
					account_no
					server_name
					server_type
					instance_id
					instance_type
					instance_price_notax
					instance_min_num
					instance_max_num
					web_scale_flag
					db_scale_flag
					disk_num
					disk_unit
					disk_price_notax
					bakcup_num
					bakcup_unit
					bakcup_price_notax
					transfer_num
					transfer_unit
					transfer_price_notax
					payment_type
					support_type
					support_price_notax
					op_price_notax
					total_price_notax
					admin_url
					admin_user
					admin_passwd
					web_url
					basic_user
					basic_passwd
					scp_user
					ftp_host
					ftp_user
					ftp_passwd
					phpmyadmin_url
					phpmyadmin_user
					phpmyadmin_passwd
					db_num
					db_host
					db_port
					db_user
					db_passwd
					db_engine
					ip
					fqdn
					os
					security_group
					bill_start
					bill_end
					contract_date
					date_add
					date_edit
					status
					op{
						uuid
						details
						price_notax
						piece
						piece_type
						sub_price_notax
						sort
					}
				}
			}`,
	scanMail : 	`query scanMail($limit: Int, $nextToken: String) {
					scanMail(limit: $limit, nextToken: $nextToken) {
						items {
							domain_name
							customer_no
							customer_name
							hostname
							password
							disk
							protect_flag
							price_notax
							bill_start
							bill_end
							contract_date
							date_add
							date_edit
							status
							disk_alert_flag
							disk_alert_to
							disk_free
							disk_hard
							disk_per
							disk_soft
							disk_use
						}
						nextToken
					}
				}`,
	getMail :	`query getMail($domain_name: String!) {
				getMail(domain_name: $domain_name) {
					domain_name
					customer_no
					customer_name
					hostname
					password
					disk
					disk_use
					protect_flag
					price_notax
					bill_start
					bill_end
					contract_date
					date_add
					date_edit
					status
					disk_alert_flag
					disk_alert_to
					disk_free
					disk_hard
					disk_per
					disk_soft
					disk_use
				}
			}`,
	createMail :	`mutation createMail($data: MailInput!) {
				createMail(input: $data) {
					domain_name
				}
			}`,
	updateMail :	`mutation updateMail($data: MailInput!) {
				updateMail(input: $data) {
					domain_name
				}
			}`,
	scanMailWhitelist : 	`query scanMailWhitelist($limit: Int, $nextToken: String) {
					scanMailWhitelist(limit: $limit, nextToken: $nextToken) {
						items {
							ip
							customer_no
						}
						nextToken
					}
				}`,
	createMailWhitelist :	`mutation createMailWhitelist($data: MailWhitelistInput!) {
		createMailWhitelist(input: $data) {
			ip
		}
	}`,
	deleteMailWhitelist :	`mutation deleteMailWhitelist($ip: String!) {
		deleteMailWhitelist(ip: $ip) {
			ip
		}
	}`,
	executeLambdaAWSSupport :	`mutation executeLambdaAWSSupport($account_no : String!, $support_name: String!) {
		executeLambdaAWSSupport(account_no: $account_no, support_name: $support_name) {
				result
				error
			}
		}`,
	createAWSSupport :	`mutation createAWSSupport($data: AWSSupportInput!) {
		createAWSSupport(input: $data) {
			case_id
		}
	}`,
	updateAWSSupport :	`mutation updateAWSSupport($case_id: String!) {
		updateAWSSupport(case_id: $case_id) {
			case_id
		}
	}`,
	queryAWSSupport : 	`query queryAWSSupport($account_no: String!, $limit: Int, $nextToken: String) {
		queryAWSSupport(account_no: $account_no, limit: $limit, nextToken: $nextToken) {
			items {
				case_id
				user_name
				account_no
				customer_no
				type
				subject
				text_value
				file1_original_name
				file2_original_name
				file3_original_name
				date_add
				status
				build_id
				build_title
				build_price
				build_period
				build_type
				build_off
				build_description
				onocomm_flag
			}
			nextToken
		}
	}`,
	getAWSSupport :	`query getAWSSupport($case_id: String!) {
		getAWSSupport(case_id: $case_id) {
			case_id
			user_name
			account_no
			customer_no
			type
			subject
			text_value
			file1_original_name
			file1_name
			file2_original_name
			file2_name
			file3_original_name
			file3_name
			date_add
			status
			build_id
			build_title
			build_price
			build_period
			build_type
			build_off
			build_description
			onocomm_flag
		}
	}`,
	createAWSSupportComment :	`mutation createAWSSupportComment($data: AWSSupportCommentInput!) {
		createAWSSupportComment(input: $data) {
			case_id
			date
		}
	}`,
	queryAWSSupportComment : 	`query queryAWSSupportComment($case_id: String!, $limit: Int, $nextToken: String) {
		queryAWSSupportComment(case_id: $case_id, limit: $limit, nextToken: $nextToken) {
			items {
				case_id
				date
				user_name
				account_no
				customer_no
				text_value
				file1_original_name
				file1_name
				file2_original_name
				file2_name
				file3_original_name
				file3_name
				onocomm_flag
				ai_flag
			}
			nextToken
		}
	}`,

};

export default GraphQL;
