import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import Decimal from 'decimal.js';

const Default = () => {

	const history = useHistory();
	const { account_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);

	useEffect(() => {
	
		const getData = async () => {
			
			//件数の取得
			const newData = await API.AWSBilling.Query(account_no);

			const aggregateData = newData.items.reduce((acc, item) => {
				const key = `${item.account_no}-${item.date}`;
				if (!acc[key]) {
					acc[key] = { ...item, cost: new Decimal(item.cost) };
				} else {
					acc[key].cost = acc[key].cost.plus(item.cost);
					if (item.invoice_id && item.invoice_id.length > 0) {
						acc[key].invoice_id = item.invoice_id;
					}
					if (parseInt(item.date_add) > parseInt(acc[key].date_add)) {
						acc[key].date_add = item.date_add;
					}
				}
				return acc;
			}, {});
			
			// 最終的な結果をDecimalオブジェクトから数値に変換
			const result = Object.values(aggregateData).map(item => ({
				...item,
				cost: item.cost.toFixed(4) // 必要に応じて適切な桁数に調整
			}));
			
			//console.log(result);

			//データセット
			setData(result);
			setView(result);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/aws/' + row.account_no + '/billing/' + row.date)}>
					詳細
				</Button>
			</div>
		);
	};

	const formatDate = (cell) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour		 = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute	 = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const second	 = ('00' + dateObj.getSeconds().toString()).slice(-2);
			const dateText     = (year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second);
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};

	const formatCost = (cell) => {
		// 数値を四捨五入し、小数点第二位まで保持
		const rounded = Math.round(Number(cell ?? 0) * 100) / 100;
	  
		// Intl.NumberFormatを使用して数値をカンマ区切りにする
		const formattedNumber = new Intl.NumberFormat('en-US', {
		  minimumFractionDigits: 2, // 小数点以下2桁を保証
		  maximumFractionDigits: 2, // 小数点以下2桁まで表示
		}).format(rounded);
	  
		return formattedNumber + ' USD';
	};

	const columns = [
		{
			dataField: 'dummy',
			isDummyField: true,
			text: '詳細',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'date',
			text: '利用月',
			sort: true,
			formatter: (cell) => { return(`${cell}`.slice(0, 4) + '年' + `${cell}`.slice(4, 6) + '月'); },
		},
		{
			dataField: 'account_no',
			text: 'アカウント番号',
			sort: true,
		},
		{
			dataField: 'cost',
			text: '利用代金',
			sort: true,
			classes: 'text-right',
			formatter: formatCost,
		},
		{
			dataField: 'invoice_id',
			text: '請求確定',
			sort: true,
			formatter: (cell) => { return(cell ? '確定' : '未確定'); },
		},
		{
			dataField: 'date_add',
			text: '更新時間',
			sort: true,
			formatter: formatDate,
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<Row>
					<Col sm={12}>
						<h4>AWSアカウントの課金情報</h4>
						<h5>【本機能はβ版となります。請求書の金額が正しい金額となります。】</h5>
					</Col>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1}>
								        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
								</Col>
							</Row>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'date'} data={view} columns={columns} checkbox={false} sort={ {field: 'date', order: 'desc'} } />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
